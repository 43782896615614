<template lang="pug">
div(
  :class="className"
  :style="customStyle")
  slot
</template>
<script>
export default {
  name: "FlexContainer",
  props: {
    className: {
      type: String,
      default: ""
    },
    customStyle: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../less/main.less";
.baseStyle {
  display: flex;
}
</style>
