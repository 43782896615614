<template lang="pug">
div(:style="{ padding: '1rem' }", v-if="showroomId !== null")
  a-spin(:style="{ display: 'block', margin: '1rem auto 0' }", v-if="loading")
    a-icon(slot="indicator", type="loading", style="font-size: 170px", spin)
  add-to-calendar(
    v-else
    title="Showroom",
    :location="`${origin}/showRoomComercial/${showroom.id}`",
    :start="showroom.scheduled_for",
    :end="new Date(showroom.scheduled_for ? showroom.scheduled_for.getTime() + 1 : new Date().getTime)",
    :details="detail",
    inline-template
  )
    div
      google-calendar#google-calendar
        div(:style="{ textAlign: 'left', fontSize: '1.2rem' }") Add to Google calendar
      microsoft-calendar#microsoft-calendar
        div(:style="{ textAlign: 'left', fontSize: '1.2rem' }") Add to Microsoft live calendar
      office365-calendar#office365-calendar
        div(:style="{ textAlign: 'left', fontSize: '1.2rem' }") Add to Office365 outlook calendar
</template>
<script>
import { LOAD_FULL_SHOWROOM_DATA } from "@/store/actions.type";

export default {
  name: "CalendarPanel",
  props: {
    showroomId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      origin: window.origin,
      loading: false
    };
  },
  computed: {
    showroom() {
      return this.$store.getters.getShowRoomById(this.$props.showroomId);
    },
    detail() {
      let attendantsList = "";
      for (let att of this.showroom.attendants) {
        attendantsList += `\t${att.client.name} --- ${att.client.email} \n`;
      }
      let seriesList = "";

      for (let series of this.showroom.scheduled_series || []) {
        seriesList += `\t${series.serie.name}  \n`;
      }

      return `Presentacion comercial ShowRoom
Invitados:
${attendantsList}
Series:
${seriesList}
`;
    }
  },
  watch: {
    showroomId: {
      immediate: true,
      async handler(showroomId) {
        if (showroomId === null) return;
        let scheduled_series = this.$store.getters.getShowRoomById(showroomId)
          .scheduled_series;

        if (scheduled_series === null || scheduled_series === undefined) {
          this.loading = true;
          await this.$store.dispatch(LOAD_FULL_SHOWROOM_DATA, showroomId);
          this.loading = false;
        }
      }
    }
  }
};
</script>
<style lang="less"></style>
