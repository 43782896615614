<template lang="pug">
layout-content(:style="{overflowY:'auto',height:'100vh'}")
  a-modal(
    v-model="calendarVisible"
    title="Agregar al calendario"
  )
    calendar-panel(:showroom-id="calendarShowroomId")
  a-modal(
    v-model="detailVisible",
    :footer="null",
    title="Detalles ShowRooms",
    :width="800"
  )
    div(:style="{padding:'1rem'}")
      showroom-detail(
        :showroom="detailShowroom",
        :loading="isLoadingShowRoomData"
      )

  a-modal(
    v-model="editVisible",
    title="Editar ShowRoom",
    :width="670",
    @ok="handleEdit"
  )
    update-showroom(
      ref="editForm"
      :showroom="updateDetailShowroom",
      :loading="isLoadingShowRoomData || isLoadingShowRoomUpdate"
    )

  div(
    :class="{ 'dashboard-container': 'true', 'not-supervisor': !userIsSupervisor }"
  )
    .dashboard-titles-container
      app-h2(content="Panel Administrativo")
      app-text(
        className="regular",
        :customStyle="{ color: 'var(--grey-color)' }",
        :content="dateToday()"
      )

    app-h2(:customStyle="{ marginTop: '1rem' }", content="The Digital ShowRoom")
    .dashboard-inputs-container
      a-input-search(enter-button, @search="onSearch", :placeholder="userIsSupervisor ? 'Nombre / Correo Cliente, Nombre Comercial Asignado' : 'Nombre / Correo Cliente'")
      modal(
        title="Crear ShowRooms",
        :width="670",
        :footer="null",
        handlerType="primary",
        handlerLabel="Crear",
        :centered="true",
        :closable="false",
        :activateModal="visibleCreate"
      )
        create-showroom(slot="modalContent", @off-modal="visibleCreate = !visibleCreate")
    a-tabs(v-model="currentTable", v-if="userIsSupervisor")
      a-tab-pane(key="user", tab="ShowRooms de usuario")
        data-table(
          ref="table"
          @change="onTableChange",
          :loading="isLoadingShowRoomList || isLoadingDeactivate || isLoadingUser || isLoadingShowRoomCreation",
          :showrooms=" searchField ? searchShowroomList : showRoomList ",
          :total="searchField ? searchShowroomListCount : showRoomListCount",
          @action-select="onActionSelect"
        )
      a-tab-pane(key="supervisor", tab="ShowRooms de supervisados")
        data-table(
          ref="supervisorTable"
          @change="onSupervisorTableChange",
          :loading="isLoadingSupervisor || isLoadingDeactivate",
          :showrooms=" supervisorSearchField ? supervisorSearchShowroomList : supervisorShowRoomList ",
          :total=" supervisorSearchField ? supervisorSearchShowroomListCount : supervisorShowRoomListCount"
          @action-select="onActionSelect"
          supervisor
        )
    data-table(
      v-else,
      ref="table"
      @change="onTableChange",
      :loading="isLoadingShowRoomList || isLoadingDeactivate || isLoadingUser || isLoadingShowRoomCreation",
      :showrooms=" searchField ? searchShowroomList : showRoomList ",
      :total="searchField ? searchShowroomListCount : showRoomListCount",
      @action-select="onActionSelect"
    )

</template>
<script>
//Import atoms components
import AppH2 from "@/components/atoms/AppH2.vue";
import AppText from "@/components/atoms/AppText.vue";

//Import organisms components
import LayoutContent from "@/components/organisms/LayoutContent.vue";
import UpdateShowroom from "./UpdateShowroom";
import Modal from "@/components/organisms/Modal.vue";

import {
  FETCH_SHOWROOM_LIST,
  DEACTIVATE_SHOWROOM,
  START_SHOWROOM_PRESENTATION,
  FETCH_SUPERVISOR_SHOWROOM_LIST,
  LOAD_FULL_SHOWROOM_DATA,
  UPDATE_SHOWROOM,
  FETCH_SEARCH_SHOWROOM_LIST,
  FETCH_SUPERVISOR_SEARCH_SHOWROOM_LIST
} from "@/store/actions.type";

//Import helpers
import { dateToday } from "@/common/helpers.js";

//import Component to modal
import CreateShowroom from "@/components/customComponents/Dashboard/CreateShowroom";
import ShowroomDetail from "@/components/customComponents/Dashboard/ShowroomDetail";
import DataTable from "@/components/customComponents/Dashboard/DataTable";
import CalendarPanel from "@/components/molecules/CalendarPanel";
import { mapGetters } from "vuex";

export default {
  name: "ShowroomsList",
  components: {
    AppH2,
    DataTable,
    AppText,
    LayoutContent,
    Modal,
    CreateShowroom,
    ShowroomDetail,
    UpdateShowroom,
    CalendarPanel
  },
  data() {
    return {
      isLoadingDeactivate: false,
      isLoadingUser: false,
      isLoadingSupervisor: false,
      band_create: false,
      visibleCreate: false,
      detailVisible: false,
      detailShowroomId: null,
      calendarVisible: false,
      currentTable: "user",
      supervisorSearchField: "",
      editVisible: false,
      searchField: "",
      updateId: null,
      calendarShowroomId: null
    };
  },
  computed: {
    ...mapGetters([
      "isLoadingShowRoomList",
      "isLoadingShowRoomPresent",
      "showRoomListFetchStatus",
      "showRoomListCount",
      "supervisorShowRoomListFetchStatus",
      "supervisorShowRoomListCount",
      "isLoadingShowRoomData",
      "isLoadingShowRoomCreation",
      "searchShowroomList",
      "searchShowroomListCount",
      "supervisorSearchShowroomListCount",
      "supervisorSearchShowroomList",
      "isLoadingShowRoomUpdate"
    ]),
    detailShowroom() {
      if (this.detailShowroomId === null) return {};
      let allShowrooms = [
        ...this.showRoomList,
        ...this.supervisorSearchShowroomList,
        ...this.searchShowroomList,
        ...this.supervisorShowRoomList
      ];
      let showroom = allShowrooms.find(
        s => s.id === this.detailShowroomId && s.scheduled_series
      );
      if (!showroom) {
        this.$store.dispatch(LOAD_FULL_SHOWROOM_DATA, this.detailShowroomId);
        return {};
      } else return showroom;
    },
    updateDetailShowroom() {
      if (this.updateId === null) return {};
      let allShowrooms = [
        ...this.showRoomList,
        ...this.supervisorSearchShowroomList,
        ...this.searchShowroomList,
        ...this.supervisorShowRoomList
      ];
      let showroom = allShowrooms.find(
        s => s.id === this.updateId && s.scheduled_series
      );
      if (!showroom) {
        this.$store.dispatch(LOAD_FULL_SHOWROOM_DATA, this.updateId);
        return {};
      } else return showroom;
    },
    userIsSupervisor() {
      return this.$session.get("user").is_supervisor;
    },
    showRoomList() {
      let showRoomList = this.$store.getters.showRoomList;
      if (showRoomList == null) return [];
      return showRoomList;
    },
    supervisorShowRoomList() {
      let showRoomList = this.$store.getters.supervisorShowRoomList;
      if (showRoomList == null) return [];
      return showRoomList;
    }
  },
  async mounted() {
    if (
      this.showRoomListFetchStatus === "no-initial-fetch" &&
      !this.isLoadingShowRoomList
    ) {
      await this.$store.dispatch(FETCH_SHOWROOM_LIST);
    }
    if (
      this.supervisorShowRoomListFetchStatus === "no-initial-fetch" &&
      !this.isLoadingSupervisor &&
      this.userIsSupervisor
    ) {
      this.isLoadingSupervisor = true;
      await this.$store.dispatch(FETCH_SUPERVISOR_SHOWROOM_LIST);
      this.isLoadingSupervisor = false;
    }
  },
  methods: {
    async handleEdit() {
      let [id, newData] = this.$refs.editForm.getData();
      if (newData === null) return;
      let response = await this.$store.dispatch(UPDATE_SHOWROOM, {
        id,
        newData
      });
      if (response.status >= 200 && response.status < 300) {
        this.editVisible = false;
        this.$message.success("Actualizado con Éxito");
      } else {
        this.$message.error("Error al editar el ShowRoom");
      }
    },
    async onSearch(value) {
      value = value.trim();
      if (this.currentTable === "user") {
        if (this.searchField !== value) this.$refs.table.goToPage(1);
        this.searchField = value;
        if (!value) return;

        this.isLoadingUser = true;
        await this.$store.dispatch(FETCH_SEARCH_SHOWROOM_LIST, {
          search: value
        });
        this.isLoadingUser = false;
      } else if (this.currentTable === "supervisor") {
        if (this.supervisorSearchField !== value)
          this.$refs.supervisorTable.goToPage(1);
        this.supervisorSearchField = value;
        if (!value) return;
        this.isLoadingSupervisor = true;
        await this.$store.dispatch(FETCH_SUPERVISOR_SEARCH_SHOWROOM_LIST, {
          search: value
        });
        this.isLoadingSupervisor = false;
      }
    },
    dateToday,
    async onActionSelect(action, id) {
      if (action === "see-more") {
        this.detailVisible = true;
        this.detailShowroomId = id;
      } else if (action === "calendar") {
        this.calendarVisible = true;
        this.calendarShowroomId = id;
      } else if (action === "deactivate") {
        this.isLoadingDeactivate = true;
        let successfully = await this.$store.dispatch(DEACTIVATE_SHOWROOM, id);
        if (!successfully) {
          this.$message.warning(
            "No se pudo completar la acción intente más tarde"
          );
        }
        this.isLoadingDeactivate = false;
      } else if (action === "start") {
        if (this.isLoadingShowRoomPresent) return;
        await this.$store.dispatch(START_SHOWROOM_PRESENTATION, id);
        this.$session.set("showRoomComercialId", id);
        this.$router.push({ name: "ShowRoomComercial", params: { id } });
      } else if (action === "update") {
        this.editVisible = true;
        this.updateId = id;
      }
    },
    /*
      depending on the current page we request more showroom
      the store automatically knows if it has to do another request 
      or if it has already have the page availabe
    */
    async onTableChange(pagination) {
      let page = pagination.current;
      this.isLoadingUser = true;
      if (this.searchField)
        await this.$store.dispatch(FETCH_SEARCH_SHOWROOM_LIST, {
          page,
          search: this.searchField
        });
      else await this.$store.dispatch(FETCH_SHOWROOM_LIST, page);
      this.isLoadingUser = false;
    },
    async onSupervisorTableChange(pagination) {
      let page = pagination.current;
      this.isLoadingSupervisor = true;
      if (this.supervisorSearchField) {
        await this.$store.dispatch(FETCH_SUPERVISOR_SEARCH_SHOWROOM_LIST, {
          page,
          search: this.supervisorSearchField
        });
      } else {
        await this.$store.dispatch(FETCH_SUPERVISOR_SHOWROOM_LIST, page);
      }

      this.isLoadingSupervisor = false;
    }
  }
};
</script>
<style lang="less">
.dashboard-container {
  margin: 100px 30px 0;
}
.dashboard-container.not-supervisor {
  margin: 6%;
}

.dashboard-container .centered-cell {
  width: 100%;
  text-align: center !important;
}

.dashboard-container .dashboard-titles-container {
  display: flex;
  justify-content: space-between;
}

.dashboard-container .ant-input-group-wrapper {
  width: 397px !important;
  height: 32px !important;
}

.dashboard-container .dashboard-inputs-container {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0rem 0.5rem;
}

.dashboard-container.not-supervisor .dashboard-inputs-container {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0rem 1.2rem;
}

.dashboard-container table th {
  color: white;
  background: var(--primary-color) !important;
}

.dashboard-container .ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon:hover {
  background: var(--primary-color) !important;
}

.dashboard-container
  .ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background-color: var(--black-color);
  color: var(--contrast-background-color);
}

.dashboard-container .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: var(--black-color);
  color: var(--contrast-background-color);
}

.dashboard-container .dashboard-inputs-container .dashboard-inputs-container,
.ant-modal-header {
  background-color: var(--black-color) !important;
  border-bottom: 1px solid #e8e8e8;
  color: var(--white-color) !important;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-container .dashboard-inputs-container .dashboard-inputs-container,
.ant-modal-title {
  margin: 0;
  color: var(--white-color) !important;
  font-weight: 600;
  font-size: 26px;
  line-height: 22px;
  word-wrap: break-word;
}

.dashboard-container .dashboard-inputs-container .dashboard-inputs-container {
  padding: 0;
}
.ant-modal-body {
  padding: 15px;
}
</style>
