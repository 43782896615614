<template lang="pug">
.dashboardUpdate-container
  div(:style="{padding:'1rem'}",v-if="loading")
    a-spin(:style="{ display: 'block', margin: '5rem auto' }", v-if="loading")
      a-icon(slot="indicator", type="loading", style="font-size: 170px", spin)
  a-tabs(default-active-key="1", v-else)
    a-tab-pane(key="1", tab="Asistentes")
      div(:style="{padding:'1rem'}")
        client-creation-list(ref="clientTable", :data="showroom.attendants")
    a-tab-pane(key="2", tab="Fecha Agendado")
      div(:style="{padding:'1rem'}")
        div(
          :style="{ margin: '1rem 2rem', display: 'flex', flexDirection: 'column' }"
        )
          app-text(content="Fecha:", className="regular-bold")
          a-date-picker(v-model="day", @change="dateChange" :format="dateFormatList")
        div(
          :style="{ margin: '1rem 2rem', display: 'flex', flexDirection: 'column' }"
        )
          app-text(, content="Hora:", className="regular-bold")
          a-time-picker(v-model="hour", @change="dateChange")
    a-tab-pane(key="3", tab="Series preseleccionadas")
      div.series-search-container
        app-h2(content="Selecciona las series del Showroom")
        div(:style="{width:'70%'}")
          app-text(
            :customStyle="{fontSize:'1rem',marginTop:'1rem'}"
            v-if="scheduled_series.length > 0",
            content="Colecciones agregadas:"
          )
          app-text(
            v-else
            :customStyle="{fontSize:'1rem',marginTop:'1rem'}"
            content="No hay colecciones agregadas"
          )
        div(
          :style="{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }"
        )
          app-button(
            :key="item.id",
            v-for="item in scheduled_series",
            icon="close",
            :handler="() => { deleteSelectedSerie(item); }",
            :label="item.name"
            :customStyle="{marginRight:'10px'}"
          )
      search-input(
          :customStyleSearchbar="{marginTop:'-350px', paddingRight:'80px !important'}"
          :customStyleDropdown="{top:'258px !important'}"
          :modalMode="true"
          v-on:collection-preselected="addSerie"
        )
</template>
<script>
import moment from "moment";
//Import atoms components
import AppH1 from "@/components/atoms/AppH1.vue";
import AppH2 from "@/components/atoms/AppH2.vue";
import AppText from "@/components/atoms/AppText.vue";
import AppButton from "@/components/atoms/AppButton.vue";

//Import organisms components
import LayoutContent from "@/components/organisms/LayoutContent.vue";
import FlexContainer from "@/components/organisms/FlexContainer.vue";

//Import helpers
import ClientCreationList from "./ClientCreationList";
import SearchInput from "@/components/customComponents/ShowRoomComercial/SearchInput.vue";

export default {
  name: "UpdateShowroom",
  components: {
    AppH2,
    AppText,
    LayoutContent,
    AppButton,
    ClientCreationList,
    FlexContainer,
    SearchInput,
    AppH1
  },
  props: {
    showroom: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      day: null,
      hour: null,
      scheduled_series: [],
      dateFormatList: ["DD/MM/YYYY", "DD/MM/YY"]
    };
  },
  watch: {
    showroom: {
      immediate: true,
      handler(showroom) {
        if (showroom.scheduled_for) {
          this.day = moment(showroom.scheduled_for);
          this.hour = moment(showroom.scheduled_for);
        }
        if (showroom.scheduled_series) {
          this.orderCount = this.showroom.scheduled_series.length + 1;
          this.scheduled_series = this.showroom.scheduled_series.map(
            (x, idx) => ({
              ...x.serie,
              order: idx
            })
          );
        }
      }
    }
  },
  methods: {
    addSerie(serie) {
      if (!this.scheduled_series.some(s => s.id === serie.id)) {
        this.scheduled_series.push({
          ...serie,
          order: this.orderCount
        });
        this.orderCount++;
      } else {
        this.$message.error(
          "No puedes agregar una colección que ya esta agregada"
        );
      }
    },
    deleteSelectedSerie(serie) {
      this.scheduled_series = this.scheduled_series
        .filter(s => s.id !== serie.id)
        .map(s => (s.order > serie.order ? { ...s, order: s.order - 1 } : s));
      this.orderCount--;
    },
    dateChange() {
      let fecha = this.day.format("YYYY-MM-DD");
      let hora = this.hour.format("HH:mm:ss");
      let fechaFinal = fecha.concat("T", hora);
      let fechahoy = new Date(fechaFinal);
      const date = new Date(fechahoy).toISOString();
      const dateActual = new Date().toISOString();
      this.scheduled_for = date;
      if (date <= dateActual) {
        this.$message.error(
          "Debes agendar la cita para una Hora/Fecha superior a la actual"
        );
      }
    },
    getData() {
      let fecha = this.day.format("YYYY-MM-DD");
      let hora = this.hour.format("HH:mm:ss");
      let fechaFinal = fecha.concat("T", hora);
      let fechahoy = new Date(fechaFinal);
      const date = new Date(fechahoy).toISOString();
      const dateActual = new Date().toISOString();
      this.scheduled_for = date;
      if (date <= dateActual) {
        this.$message.error(
          "Debes agendar la cita para una Hora/Fecha superior a la actual"
        );
      } else {
        return [
          this.showroom.id,
          {
            attendants: this.$refs.clientTable.toJson(),
            scheduled_for: this.scheduled_for,
            scheduled_series: this.scheduled_series.map(s => ({
              serie: s.id,
              order: s.order
            }))
          }
        ];
      }
    }
  }
};
</script>
<style lang="less">
.series-search-container {
  width: 100%;
  padding: 1.5rem 2rem;
  min-height: 500px;
}

.series-search-container .ant-input-wrapper {
  width: 92%;
}
</style>
