<template lang="pug">
div(:style="customStyle")
  app-button(
    :label="handlerLabel"
    :type="handlerType"
    :handler="showModal"
    :icon="handlerIcon"
    :shape="shape"
    :customStyle="handlerCustomStyle"
    :ghost="handlerGhost")
  a-modal(
    v-model="visible"
    :footer="footer"
    :width="width"
    :title="title"
    :closable="closable"
    :centered="centered"
    :destroyOnClose="true"
    )
    slot(name="modalContent")
</template>
<script>
//Import atoms components
import AppButton from "@/components/atoms/AppButton";

export default {
  name: "Modal",
  components: {
    AppButton
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    footer: {
      type: String,
      default: ""
    },
    width: {
      type: Number,
      default: 1000
    },
    handlerLabel: {
      type: String,
      default: ""
    },
    handlerType: {
      type: String,
      default: ""
    },
    handlerIcon: {
      type: String,
      default: ""
    },
    customStyle: {
      type: Object,
      default: () => {}
    },
    activateModal: {
      type: Boolean,
      default: false
    },
    centered: {
      // to center modal
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: true
    },
    /* Atributes for buttons */
    shape: {
      type: String,
      default: null
    },
    handlerCustomStyle: {
      type: Object,
      default: () => {}
    },
    handlerGhost: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    activateModal() {
      this.visible = false;
    }
  },
  methods: {
    showModal() {
      this.visible = true;
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../less/main.less";
</style>
