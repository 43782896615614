<template lang="pug">
div
  a-spin(:style="{ display: 'block', margin: '5rem auto' }", v-if="loading")
    a-icon(slot="indicator", type="loading", style="font-size: 170px", spin)
  a-tabs(default-active-key="1", v-else)
    a-tab-pane(key="1", tab="Información Básica ")
      .showroom-field
        app-text.showroom-field-name(content="Comercial :", className="regular-bold")
        app-text(:content="showroom.seller.email", className="regular")
      .showroom-field
        app-text.showroom-field-name(content="Fecha programada :", className="regular-bold")
        app-text(
          :content="format(showroom.scheduled_for)",
          className="regular"
        )
      .showroom-field
        app-text.showroom-field-name(content="Fecha de inicio :", className="regular-bold")
        app-text(
          :content="format(showroom.effective_start) || 'Aún no ha comenzado el ShowRoom'",
          className="regular"
        )
      .showroom-field
        app-text.showroom-field-name(content="Fecha de finalización", className="regular-bold")
        app-text(
          :content="format(showroom.effective_end) || 'Aún no ha terminado el ShowRoom'",
          className="regular"
        )
    a-tab-pane(key="2", tab="Asistentes")
      a-list(item-layout="horizontal", :data-source="showroom.attendants")
        a-list-item(slot="renderItem", slot-scope="item, index")
          app-text(:content="item.client.name", className="regular")
          app-text(:content="item.client.email", className="regular")
    a-tab-pane(key="3", tab="Series preseleccionadas")
      div(v-if="showroom.scheduled_series.length !== 0").series-list
        Card(
          :loading="false",
          :customStyle="{ cursor: 'pointer' }",
          v-for="item in showroom.scheduled_series",
          :key="item.id",
          :className="'sidebar'"
        )
          app-image(
            className="sidebar-image",
            :url="imageHandler(item.serie)"
          )
          app-text(
            :style="{ textAlign: 'center', margin: '5% auto 0' }",
            :content="item.serie.name",
            className="small"
          )
      app-text(
        v-else,
        content="No hay ShowRoom preseleccionados",
        className="text-grey",
        :customStyle="{ fontSize: '2rem',marginTop:'1rem',marginLeft:'1rem' }"
      )
</template>
<script>
import AppText from "@/components/atoms/AppText.vue";

import Card from "@/components/molecules/Card.vue";
import AppImage from "@/components/atoms/AppImage.vue";
import imageHandler from "@/common/imageHandler.js";

export default {
  name: "ShowroomsDetail",
  components: {
    AppText,
    Card,
    AppImage
  },
  props: {
    showroom: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    format(date) {
      if (date) return date.toLocaleString("es");
      return null;
    },
    imageHandler
  }
};
</script>
<style lang="less">
.showroom-field {
  margin: 0.5rem 0;
}
.showroom-field-name {
  margin-right: 0.5rem !important;
}

.series-list {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
