<template lang="pug">
fragment
  a-table(
    :row-key="(record) => record.id",
    bordered,
    size="middle",
    :columns="tableColumns",
    :data-source="tableAttendants",
    :pagination="false"
  )
    template(
      v-for="col in ['name', 'email']",
      :slot="col",
      slot-scope="text, record, index"
    )
      div(:key="col")
        a-input(
          v-if="record.editable",
          style="margin: -5px 0",
          :value="text",
          @change="(e) => attendantChange(e.target.value, col)"
        )
        div(v-else)
            span {{ text }}
    template(slot="Opciones", slot-scope="text, record, index")
      .editable-row-Opcioness
        div(v-if="record.editable")
          a(@click="() => cancelEdit(record.id)") Cancelar
          a-divider(type="vertical")
          a(@click="() => editAttendant(record.id)") Confirmar
        div(v-else)
          a(:disabled="editingAttendant", @click="() => startEdit(record.id)") Editar
          a-divider(type="vertical")
          a(@click="() => deleteAttendant(record.id)") Eliminar
  div(
    :style="{ display: 'flex', padding: '1rem 0 0', justifyContent: 'flex-end' }"
  )
    app-button(label="Agregar asistente", :handler="addAttendant")
</template>
<script>
import AppButton from "@/components/atoms/AppButton.vue";

const TABLE_COLUMNS = [
  {
    title: "Nombre",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
    width: 180
  },
  {
    title: "Email",
    dataIndex: "email",
    scopedSlots: { customRender: "email" },
    width: 250
  },
  {
    title: "Opciones",
    dataIndex: "Opciones",
    scopedSlots: { customRender: "Opciones" }
  }
];

export default {
  name: "ClientCreationList",
  components: {
    AppButton
  },
  props: {
    data: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      tableColumns: TABLE_COLUMNS,
      tableAttendants: [],
      editingAttendant: true
    };
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.idCounter = 0;
        if (this.$props.data) {
          let list = [];
          for (let attendant of this.$props.data) {
            this.idCounter++;
            let { email, name, editable } = attendant.client;
            list.push({
              email,
              name,
              editable: editable !== undefined ? editable : false,
              id: this.idCounter
            });
          }
          this.editingAttendant = false;
          this.tableAttendants = list;
        } else {
          this.tableAttendants = [
            {
              name: "",
              email: "",
              editable: true,
              id: this.idCounter
            }
          ];
        }
      }
    }
  },
  methods: {
    attendantChange(value, col) {
      if (col === "name") {
        this.nameValue = value;
      } else if (col === "email") {
        this.emailValue = value;
      }
    },
    cancelEdit(id) {
      this.tableAttendants = this.tableAttendants.map(ta => {
        if (ta.id !== id) return ta;
        return {
          ...ta,
          editable: false
        };
      });
      this.editingAttendant = false;
    },
    editAttendant(id) {
      let emailRegex = /^[^@\s]+@[^@\s.]+\.[^@.\s]+$/;
      if (!this.emailValue || !this.nameValue.trim()) {
        this.$message.error("Los clientes deben tener Nombre y Correo.");
      } else if (
        this.tableAttendants.some(
          ta => ta.email === this.emailValue && ta.id != id
        )
      ) {
        this.$message.error("Los correos no deben repetirse.");
      } else if (!emailRegex.test(this.emailValue)) {
        this.$message.error("Correo Inválido.");
      } else if (this.$session.get("user").email === this.emailValue) {
        this.$message.error("No puedes invitarte a ti mismo.");
      } else {
        this.tableAttendants = this.tableAttendants.map(ta => {
          if (ta.id !== id) return ta;
          return {
            ...ta,
            email: this.emailValue,
            name: this.nameValue,
            editable: false
          };
        });
        this.editingAttendant = false;
        this.$emit("change");
      }
    },
    startEdit(id) {
      this.tableAttendants = this.tableAttendants.map(ta => {
        if (ta.id !== id) return ta;
        this.emailValue = ta.email;
        this.nameValue = ta.name;
        return {
          ...ta,
          editable: true
        };
      });
      this.editingAttendant = true;
    },
    addAttendant() {
      this.idCounter++;
      this.tableAttendants = [
        ...this.tableAttendants,
        {
          name: "",
          email: "",
          id: this.idCounter,
          editable: false
        }
      ];
    },
    deleteAttendant(id) {
      this.tableAttendants = this.tableAttendants.filter(a => a.id !== id);
    },
    toJson() {
      let attendants = this.tableAttendants;
      if (attendants.length === 0) {
        this.$message.error("Debes agregar al menos 1 cliente");
        return null;
      }
      if (attendants.some(a => !a.email.trim() || !a.name.trim())) {
        this.$message.error("Los clientes deben tener Nombre y Correo");
        return null;
      }

      return attendants.map(ta => {
        return {
          client: {
            email: ta.email.toLowerCase(),
            name: ta.name
          }
        };
      });
    }
  }
};
</script>
