<template lang="pug">
fragment
  div(:style="{padding:'1rem'}",v-if="isLoadingShowRoomCreation")
    a-spin(:style="{ display: 'block', margin: '5rem auto' }", v-if="isLoadingShowRoomCreation")
      a-icon(slot="indicator", type="loading", style="font-size: 170px", spin)
  .dashboardCreated-container(v-else)
    .slider-Container
      a-steps(:current="current")
        a-step(v-for="item in 4", :key="item")
      .steps-content
        p {{ array_info[current] }}
    .dashboardCreated-containerForms
      //-Provisional form for varying form selection -//

      .client-table(v-if="current === 0")
        client-creation-list(@change="onClientTableChange",:data="attendants",ref="clientTable")
      a-form(:form="form", ref="anyName")
        a-form-item(v-if="current == 1", label="Fecha", style="font-weight:bold")
          a-date-picker(
            v-if="current == 1",
            v-model="day",
            style="width: 100%"
            :format="dateFormatList"
          )
        a-form-item(v-if="current == 1", label="Hora", style="font-weight:bold")
          a-time-picker(
            v-if="current == 1",
            v-model="hour",
            style="width: 100%"
          )

        search-input(
          v-if="current == 2"
          :customStyleSearchbar="{marginTop:'-130px', paddingRight:'50px !important'}"
          :modalMode="true"
          v-on:collection-preselected="addSerie"
          )
        app-text(
          v-if="scheduled_series.length > 0 && current == 2",
          :customStyle="{paddingLeft:'30px'}"
          content="Colecciones agregadas"
        )
        div(
          v-if="current == 2"
          :style="{ display: 'flex', justifyContent: 'flex-start', alignItems: 'start', flexWrap: 'wrap', paddingLeft:'30px' }"
        )
          app-button(
            :key="item.id",
            v-for="item in scheduled_series",
            icon="close",
            :handler="() => { deleteSelectedSerie(item); }",
            :label="item.name"
          )

        div(class="Container-Confirmacion" , v-if="current == 3")
          app-text(
            :customStyle="{margin: '1px 10px'}"
            class="Titulos"
            content="Agendado Para:"
          )
          app-text(
            :customStyle="{margin: '1px 10px'}"
            class="Lista"
            :content="`Fecha: ${moment(scheduled_for).format('DD MM YYYY HH:mm:ss')}`"
          )
          app-text(
            :customStyle="{margin: '1px 10px'}"
            class="Titulos"
            content="Clientes:"
          )
          div(v-for="aux in attendants",:key="aux.client.email")
            app-text(
              :customStyle="{margin: '1px 10px'}"
              class="Lista"
              :content="`Nombre: ${aux.client.name}`"
            )
            app-text(
              :customStyle="{margin: '1px 10px'}"
              class="Lista"
              :content="`Correo: ${aux.client.email}`"
            )
              //- :content="scheduled_for.toLocaleString('es')"
          app-text(
            :customStyle="{margin: '1px 10px'}"
            class="Titulos"
            content="Colecciones:"
          )
          div(:style="{display:'flex'}")
            app-text(
              class="badge"
              :key="item.id"
              v-for="item,idx in scheduled_series"
              :content="`${item.name}${idx === scheduled_series.length - 1 ? ''  : ','}`"
            )
    .steps-action
      app-button(
        type="primary"
        :customStyle="{backgroundColor:'var(--secondary-color) !important'}"
        className="small cancel"
        :handler="prev"
        :label="current == 0 ? 'Cancelar' : 'Volver'"
      )
      app-button(
        type="primary",
        :customStyle="{backgroundColor:'var(--success-color) !important'}"
        className="small"
        :handler="next"
        :label="current == 3 ? 'Confirmar' : 'Continuar'"
      )
</template>
<script>
//Import atoms components
import AppH2 from "@/components/atoms/AppH2.vue";
import AppText from "@/components/atoms/AppText.vue";
import AppButton from "@/components/atoms/AppButton.vue";
import moment from "moment";

//Import organisms components
import LayoutContent from "@/components/organisms/LayoutContent.vue";
import FlexContainer from "@/components/organisms/FlexContainer.vue";

//Import helpers
import SearchInput from "@/components/customComponents/ShowRoomComercial/SearchInput.vue";
import ClientCreationList from "./ClientCreationList";
// Action
import { CREATE_SHOWROOM } from "@/store/actions.type";

import { mapGetters } from "vuex";

export default {
  name: "CreateShowroom",
  components: {
    AppH2,
    AppText,
    LayoutContent,
    AppButton,
    FlexContainer,
    SearchInput,
    ClientCreationList
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "coordinated" }),
      moment: moment,
      day: moment(),
      hour: moment().add(5, "minutes"),
      attendants: [{ client: { name: "", email: "", editable: true } }],
      scheduled_for: "",
      scheduled_series: [],
      current: 0,
      array_info: [
        "Asistentes del ShowRoom",
        "Establecer fecha y hora",
        "Agregue las colecciones para mostrar en el ShowRoom",
        "Confirmación"
      ],
      dateFormatList: ["DD/MM/YYYY", "DD/MM/YY"]
    };
  },
  computed: {
    ...mapGetters(["isLoadingShowRoomCreation"])
  },
  mounted() {
    this.orderCount = 1;
  },
  methods: {
    onClientTableChange() {
      this.attendants = this.$refs.clientTable.toJson();
    },
    /* methods to move in steps*/
    async next() {
      if (this.current == 0) {
        if (this.$refs.clientTable.toJson()) this.current++;
      } else if (this.current == 1) {
        let fecha = this.day.format("YYYY-MM-DD");
        let hora = this.hour.format("HH:mm:ss");
        let fechaFinal = fecha.concat("T", hora);
        let fechahoy = new Date(fechaFinal);
        const date = new Date(fechahoy).toISOString();
        const dateActual = new Date().toISOString();
        this.scheduled_for = date;
        if (date <= dateActual) {
          this.$message.error(
            "Debes agendar la cita para una Hora/Fecha superior a la actual"
          );
        } else {
          this.$message.success("Fecha Registrada");
          this.current++;
        }
      } else if (this.current == 2) {
        this.current++;
      } else if (this.current == 3) {
        /* we create this variable instead of setting this.scheduled_series
        in case there is an error and we need to have the unprepared data */
        let scheduled_series = this.scheduled_series.map(s => ({
          serie: s.id,
          order: s.order
        }));
        const data = {
          scheduled_for: this.scheduled_for,
          scheduled_series,
          attendants: this.attendants
        };

        let response = await this.$store.dispatch(CREATE_SHOWROOM, data);
        if (response.status === 400) {
          let errorMsg =
            "Ya posee una cita pendiente para esa fecha, o para los +-30 minutos a la hora Seleccionada";
          if (response.data.scheduled_for.includes(errorMsg)) {
            this.current = 1;
            this.$message.error(errorMsg);
          } else {
            this.$message.error("Error al crear el ShowRoom");
          }
        } else {
          this.$message.success(`ShowRoom ${response.data.id} creado !`);
          this.$emit("off-modal");
        }
      }
    },

    prev() {
      // close modal
      if (this.current == 0) {
        this.$emit("off-modal");
      } else {
        this.current--;
      }
    },
    //Function for add single serie
    addSerie(serie) {
      if (!this.scheduled_series.some(s => s.id === serie.id)) {
        this.scheduled_series.push({
          ...serie,
          order: this.orderCount
        });
        this.orderCount++;
      } else {
        this.$message.error(
          "No puedes agregar una colleción que ya esta agregada"
        );
      }
    },
    //Function for deleted selected serie
    deleteSelectedSerie(serie) {
      this.scheduled_series = this.scheduled_series
        .filter(s => s.id !== serie.id)
        .map(s => (s.order > serie.order ? { ...s, order: s.order - 1 } : s));
      this.orderCount--;
    }
  }
};
</script>
<style lang="less" scoped>
.dashboardCreated-container {
  width: 100%;
  min-height: 537px;
  display: flex;
  padding: 2rem 0;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // background:green;
}

.slider-Container {
  width: 80%;
}

.dashboardCreated-containerForms {
  // background:Red;
  width: 90%;
}

.steps-content p {
  font-weight: bolder;
}

.steps-content {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding-top: 30px;
  line-height: 21.94px;
}

.steps-action {
  margin-top: 24px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // background:blue;
}

.Container-Confirmacion {
  width: 100%;
  height: 100%;
  // border:1px solid black;
  // background:red;
  // margin:5px 30px;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  // overflow-y: scroll;
}

.Lista {
  font-size: 16px;
  color: var(--black-color);
  margin: 0;
}

.Titulos {
  color: var(--black-color);
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
}
.badge {
  border-radius: 7px;
  padding: 0 10px;
  border: 1px solid var(--primary-color);
  margin: 10px;
}
div.ant-steps-item-icon {
  background: var(--primary-color) !important;
}
</style>
