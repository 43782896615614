<template lang="pug">
a-table(
  :loading="loading",
  :data-source="showrooms",
  :row-key="(record) => record.id",
  :columns="tableColumns",
  :pagination="pagination",
  @change="onTableChange"
)
  .centered-cell(slot="scheduled_for-render", slot-scope="scheduled_for")
    span {{ scheduled_for ? scheduled_for.toLocaleString('es') : '-' }}
  .centered-cell(slot="effective_start-render", slot-scope="effective_start")
    span {{ effective_start ? effective_start.toLocaleString('es') : '-' }}
  .centered-cell(slot="effective_end-render", slot-scope="effective_end")
    span {{ effective_end ? effective_end.toLocaleString('es') : '-' }}
  div(slot="actions-render", slot-scope="showroom")
    a-select(
      :value="value"
      :style="{ width: '120px' }",
      @change="onActionSelect($event, showroom.id)"
    )
      a-select-option(value="no-select") Acciones
      a-select-option(value="see-more") Ver más
      a-select-option(value="calendar",v-if="!supervisor") Calendario
      a-select-option(value="start",v-if="!supervisor",:disabled="!canPresent(showroom)")
        span(v-if="canPresent(showroom)") Presentar
        div(v-else)
          a-tooltip
            span Presentar
            template(slot="title")
              span Solo se puede presentar un Showroom ‌no‌ caducado,‌ ‌finalizado‌ ‌ni‌ ‌suspendido.
      a-select-option(value="update",v-if="!supervisor",:disabled="!canEdit(showroom)")
        span(v-if="canEdit(showroom)") Editar
        div(v-else)
          a-tooltip
            span Editar
            template(slot="title")
              span No puedes editar un showroom Caducado, Presentado o En presentación.
      a-select-option(value="deactivate",v-if="!supervisor",:disabled="!canSuspend(showroom)")
        span(v-if="canSuspend(showroom)") Suspender
        div(v-else)
          a-tooltip
            span Suspender
            template(slot="title")
              span No puedes suspender un showroom Caducado, Presentado o En presentación..
</template>
<script>
const TABLE_COLUMNS = [
  {
    title: "ID",
    dataIndex: "id",
    sorter: (a, b) => a.id - b.id,
    sortDirections: ["descend", "ascend"],
    align: "center",
    defaultSortOrder: "descend"
  },
  {
    title: "Comercial asignado",
    dataIndex: "seller.email",
    sorter: (a, b) => a.sellerName - b.sellerName,
    sortDirections: ["descend", "ascend"]
  },
  {
    title: "Fecha / Hora inicio",
    dataIndex: "scheduled_for",
    sorter: (a, b) => a.scheduled_for - b.scheduled_for,
    sortDirections: ["descend", "ascend"],
    scopedSlots: { customRender: "scheduled_for-render" },
    align: "center"
  },
  {
    title: "Inicio efectivo",
    dataIndex: "effective_start",
    sorter: (a, b) => a.effective_start - b.effective_start,
    sortDirections: ["descend", "ascend"],
    scopedSlots: { customRender: "effective_start-render" },
    align: "center"
  },
  {
    title: "Fecha/Hora Fin",
    dataIndex: "effective_end",
    sorter: (a, b) => a.effective_end - b.effective_end,
    sortDirections: ["descend", "ascend"],
    scopedSlots: { customRender: "effective_end-render" },
    align: "center"
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: (a, b) => a.status - b.status,
    sortDirections: ["descend", "ascend"],
    align: "center",
    filters: [
      { text: "Presentado", value: "Presentado" },
      { text: "En presentación", value: "En presentación" },
      { text: "Preparado", value: "Preparado" },
      { text: "Por preparar", value: "Por preparar" },
      { text: "Caducado", value: "Caducado" }
    ],
    onFilter: (value, record) => record.status.includes(value)
  },
  {
    title: "Acciones",
    scopedSlots: { customRender: "actions-render" },
    align: "center"
  }
];

export default {
  name: "DataTable",
  props: {
    loading: {
      required: true,
      type: Boolean
    },
    showrooms: {
      required: true,
      type: Array
    },
    total: {
      required: true,
      type: Number
    },
    supervisor: {
      default: false,
      type: Boolean
    }
  },
  data() {
    let tableColumns = TABLE_COLUMNS;
    if (!this.$props.supervisor)
      tableColumns = tableColumns.filter(
        col => col.title != "Comercial asignado"
      );
    return {
      tableColumns,
      value: "no-select",
      pagination: { pageSize: 15 }
    };
  },
  watch: {
    total: function(total) {
      this.pagination = { ...this.pagination, total: total };
    }
  },
  mounted() {
    this.pagination = { ...this.pagination, total: this.total };
  },
  methods: {
    goToPage(page) {
      this.pagination = { ...this.pagination, current: page };
    },
    canPresent(showroom) {
      return (
        !showroom.effective_start | showroom.effective_start &&
        !showroom.effective_end &&
        !showroom.expired | showroom.is_showing
      );
    },
    canEdit(showroom) {
      return !showroom.expired && !showroom.is_showing && !showroom.presented;
    },
    canSuspend(showroom) {
      return !showroom.expired && !showroom.is_showing && !showroom.presented;
    },
    onTableChange(pagination) {
      this.pagination = { ...this.pagination, current: pagination.current };
      this.$emit("change", pagination);
    },
    onActionSelect(value, id) {
      this.$emit("action-select", value, id);
    }
  }
};
</script>
<style lang="less"></style>
