<template lang="pug">
layout
  side-bar-left
  showrooms-panel
</template>
<script>
//Import organisms components
import Layout from "@/components/organisms/Layout.vue";

//Import custom components of this component
import SideBarLeft from "@/components/customComponents/Dashboard/SideBarLeft.vue";
import ShowroomsPanel from "@/components/customComponents/Dashboard/ShowroomsPanel.vue";

export default {
  name: "ShowroomsList",
  components: {
    Layout,
    SideBarLeft,
    ShowroomsPanel
  }
};
</script>
