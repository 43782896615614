<template lang="pug">
h1(
  :class="shape"
  :style="customStyle") {{content}}
</template>
<script>
export default {
  name: "AppH1",
  props: {
    content: {
      type: String,
      default: ""
    },
    shape: {
      type: String,
      default: ""
    },
    customStyle: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../less/main.less";
</style>
